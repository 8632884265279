import React from 'react';
import './App.css';
import img1 from './static/images/fei1.jpeg';
import img2 from './static/images/fei2.jpeg';
import img3 from './static/images/fei3.jpeg';
import img4 from './static/images/jiaomin2.jpg';
import img5 from './static/images/jiaomin3.jpg';
import img6 from './static/images/jiaomin4.jpg';
import img7 from './static/images/sue.jpeg';

const App: React.FC = () => {
  const images = [img1, img2, img3, img4, img5, img6, img7];

  return (
    <div className="App">
      <header className="App-header center">
        <h1>Shiya Luo</h1>
        <h2>Makeup Artist</h2>
        <p>San Francisco</p>
      </header>
      <div className="images">
        {images.map((image, index) => (
          <img
            className="square-image"
            alt={`wedding ${index}`}
            key={index}
            src={image}
          />
        ))}
      </div>
      <div className="contact center">
        <h1>Contact</h1>
        <h2>me@shiya.io</h2>
      </div>
    </div>
  );
};

export default App;
